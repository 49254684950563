<template>
  <g>
    <svg:style>
      .cls-10, .cls-11, .cls-3, .cls-4, .cls-5, .cls-6, .cls-7, .cls-8, .cls-9 {
        stroke: #aeaeae;
        stroke-miterlimit: 10;
        stroke-width: 0.25px;
      }

      .cls-3 {
        fill: url(#inox-gradient);
      }

      .cls-4 {
        fill: url(#inox-gradient-2);
      }

      .cls-5 {
        fill: url(#inox-gradient-3);
      }

      .cls-6 {
        fill: url(#inox-gradient-4);
      }

      .cls-7 {
        fill: url(#glass-pattern);
      }

      .cls-8 {
        fill: url(#glass-pattern);
      }

      .cls-9 {
        fill: url(#handle-gradient);
      }

      .cls-10 {
        fill: url(#inox-gradient-5);
      }

      .cls-11 {
        fill: url(#glass-pattern);
      }
    </svg:style>
    <linearGradient id="inox-gradient" 
      :x1="doorLeftWidth1 + 56.62" 
      :y1="doorTopHeight1 + 93.76" 
      :x2="doorLeftWidth1 + 84.86" 
      :y2="doorTopHeight1 + 93.76" 
      gradientUnits="userSpaceOnUse">
      <stop offset="0.01" stop-color="#babbbc"/>
      <stop offset="0.29" stop-color="#989a9e"/>
      <stop offset="0.65" stop-color="#bdbdc1"/>
      <stop offset="0.87" stop-color="#9d9fa2"/>
      <stop offset="1" stop-color="#a6a8aa"/>
      <stop offset="1" stop-color="#c2c5c9"/>
    </linearGradient>
    <linearGradient id="inox-gradient-2" data-name="inox" 
      :x1="doorLeftWidth1 + 65.8" 
      :y1="doorTopHeight1 + 53.53" 
      :x2="doorLeftWidth1 + 75.68" 
      :y2="doorTopHeight1 + 53.53" 
      xlink:href="#inox-gradient"/>
    <linearGradient id="inox-gradient-3" data-name="inox" 
      :x1="doorLeftWidth1 + 56.62" 
      :y1="doorTopHeight1 + 206.74" 
      :x2="doorLeftWidth1 + 84.86" 
      :y2="doorTopHeight1 + 206.74" 
      xlink:href="#inox-gradient"/>
    <linearGradient id="inox-gradient-4" data-name="inox" 
      :x1="doorLeftWidth1 + 65.8" 
      :y1="doorTopHeight1 + 246.97" 
      :x2="doorLeftWidth1 + 75.68" 
      :y2="doorTopHeight1 + 246.97" 
      xlink:href="#inox-gradient"/>
    <linearGradient id="glass-gradient" 
      :x1="doorLeftWidth1 + 70.74" 
      :y1="doorTopHeight1 + 102.99" 
      :x2="doorLeftWidth1 + 70.74" 
      :y2="doorTopHeight1 + 84.63" 
      gradientUnits="userSpaceOnUse">
      <stop offset="0" stop-color="#f4f4f4"/>
      <stop offset="0.5" stop-color="#dee8e8"/>
      <stop offset="1" stop-color="#d1cece"/>
    </linearGradient>
    <linearGradient id="glass-gradient-2" data-name="glass-gradient" 
      :x1="doorLeftWidth1 + 70.74" 
      :y1="doorTopHeight1 + 215.97" 
      :x2="doorLeftWidth1 + 70.74" 
      :y2="doorTopHeight1 + 197.62" 
      xlink:href="#glass-gradient"/>
    <linearGradient id="handle-gradient" 
      :x1="doorLeftWidth1 + 11.29" 
      :y1="doorTopHeight1 + 150.25" 
      :x2="doorLeftWidth1 + 16.93" 
      :y2="doorTopHeight1 + 150.25" 
      xlink:href="#inox-gradient"/>
    <linearGradient id="inox-gradient-5" data-name="inox" 
      :x1="doorLeftWidth1 + 49.56" 
      :y1="doorTopHeight1 + 150.25" 
      :x2="doorLeftWidth1 + 91.93" 
      :y2="doorTopHeight1 + 150.25" 
      xlink:href="#inox-gradient"/>
    <linearGradient id="glass-gradient-3" data-name="glass-gradient" 
      :x1="doorLeftWidth1 + 70.74" 
      :y1="doorTopHeight1 + 166.49" 
      :x2="doorLeftWidth1 + 70.74" 
      :y2="doorTopHeight1 + 134.01" 
      xlink:href="#glass-gradient"/>
    <filter id="inset-shadow">
      <feOffset dx="0" dy="0"/>
      <feGaussianBlur stdDeviation="1"  result="offset-blur"/>
      <feComposite operator="out" in="SourceGraphic" in2="offset-blur" result="inverse"/>
      <feFlood flood-color="black" flood-opacity="1" result="color"/>
      <feComposite operator="in" in="color" in2="inverse" result="shadow"/>
      <feComponentTransfer in="shadow" result="shadow">
        <feFuncA type="linear" slope="1.25"/>
      </feComponentTransfer>
      <feComposite operator="over" in="shadow" in2="SourceGraphic"/>
    </filter>
    <g id="S20">
      <polyline id="inox"
                v-if="showInox"
                data-name="inox"
                class="cls-3"
                :points="`${doorLeftWidth1 + 56.62} ${doorTopHeight1 + 107.88} ${doorLeftWidth1 + 84.86} ${doorTopHeight1 + 107.88} ${doorLeftWidth1 + 84.86} ${doorTopHeight1 + 79.63} ${doorLeftWidth1 + 56.62} ${doorTopHeight1 + 79.63} ${doorLeftWidth1 + 56.62} ${doorTopHeight1 + 107.88}`"/>
      <polyline id="inox-2"
                v-if="showInox"
                data-name="inox"
                class="cls-4"
                :points="`${doorLeftWidth1 + 75.68} ${doorTopHeight1 + 48.59} ${doorLeftWidth1 + 65.8} ${doorTopHeight1 + 48.59} ${doorLeftWidth1 + 65.8} ${doorTopHeight1 + 58.48} ${doorLeftWidth1 + 75.68} ${doorTopHeight1 + 58.48} ${doorLeftWidth1 + 75.68} ${doorTopHeight1 + 48.59}`"/>
      <polyline id="inox-3"
                v-if="showInox"
                data-name="inox"
                class="cls-5"
                :points="`${doorLeftWidth1 + 84.86} ${doorTopHeight1 + 192.62} ${doorLeftWidth1 + 56.62} ${doorTopHeight1 + 192.62} ${doorLeftWidth1 + 56.62} ${doorTopHeight1 + 220.86} ${doorLeftWidth1 + 84.86} ${doorTopHeight1 + 220.86} ${doorLeftWidth1 + 84.86} ${doorTopHeight1 + 192.62}`"/>
      <polyline id="inox-4"
                v-if="showInox"
                data-name="inox"
                class="cls-6"
                :points="`${doorLeftWidth1 + 65.8} ${doorTopHeight1 + 251.91} ${doorLeftWidth1 + 75.68} ${doorTopHeight1 + 251.91} ${doorLeftWidth1 + 75.68} ${doorTopHeight1 + 242.03} ${doorLeftWidth1 + 65.8} ${doorTopHeight1 + 242.03} ${doorLeftWidth1 + 65.8} ${doorTopHeight1 + 251.91}`"/>
      <polyline filter="url(#inset-shadow)" id="glass"
                class="cls-7"
                :points="`${doorLeftWidth1 + 79.92} ${doorTopHeight1 + 84.63} ${doorLeftWidth1 + 61.57} ${doorTopHeight1 + 84.63} ${doorLeftWidth1 + 61.57} ${doorTopHeight1 + 102.99} ${doorLeftWidth1 + 79.92} ${doorTopHeight1 + 102.99} ${doorLeftWidth1 + 79.92} ${doorTopHeight1 + 84.63}`"/>
      <polyline filter="url(#inset-shadow)" id="glass-2"
                data-name="glass"
                class="cls-8"
                :points="`${doorLeftWidth1 + 79.92} ${doorTopHeight1 + 197.62} ${doorLeftWidth1 + 61.57} ${doorTopHeight1 + 197.62} ${doorLeftWidth1 + 61.57} ${doorTopHeight1 + 215.97} ${doorLeftWidth1 + 79.92} ${doorTopHeight1 + 215.97} ${doorLeftWidth1 + 79.92} ${doorTopHeight1 + 197.62}`"/>
      <polyline id="inox-5"
                v-if="showInox"
                data-name="inox"
                class="cls-10"
                :points="`${doorLeftWidth1 + 91.93} ${doorTopHeight1 + 129.07} ${doorLeftWidth1 + 49.56} ${doorTopHeight1 + 129.07} ${doorLeftWidth1 + 49.56} ${doorTopHeight1 + 171.44} ${doorLeftWidth1 + 91.93} ${doorTopHeight1 + 171.44} ${doorLeftWidth1 + 91.93} ${doorTopHeight1 + 129.07}`"/>
      <polyline filter="url(#inset-shadow)" id="glass-3"
                data-name="glass"
                class="cls-11"
                :points="`${doorLeftWidth1 + 54.5} ${doorTopHeight1 + 166.49} ${doorLeftWidth1 + 86.99} ${doorTopHeight1 + 166.49} ${doorLeftWidth1 + 86.99} ${doorTopHeight1 + 134.01} ${doorLeftWidth1 + 54.5} ${doorTopHeight1 + 134.01} ${doorLeftWidth1 + 54.5} ${doorTopHeight1 + 166.49}`"/>
    </g>
  </g>
</template>

<script>
export default {
  props: [
    'doorWidth',
    'doorHeight',
    'doorLeftWidth',
    'doorTopHeight',
    'doorGlassImage',
    'showInox',
  ],
  computed: {
    doorLeftWidth1() {
      return this.doorLeftWidth + this.doorWidth/2 - 71
    },
    doorTopHeight1() {
      return this.doorTopHeight + this.doorHeight/2 - 153
    },
  }
}
</script>
